<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Message Config for Score not offering {{enrolOption.name}}</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Enrol Option Not Available Text for {{enrolOption.name}}
                </div>
              </div>
              <div class="breadcrumb-right" v-if="currentUser.access_type === 'federal'">
                <div class="card-toolbar">
                  <v-btn
                      @click="createEnrolOptionScore()"
                      class="mt-1 btn btn-primary"
                      style="color:#fff;"
                  >
                    <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                   Add SCORE to this Category
                  </v-btn>
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <v-select
                      :items="scores"
                      v-model="search.score_id"
                      v-on:keyup.enter="searchEnrolOptionScore"
                      @input="search.score_id = $event !== null ? $event : ''"
                      label="SCORE"
                      item-text="name"
                      item-value="id"
                      clearable
                      outlined
                      dense
                  ></v-select>
                </v-col>

<!--                <v-col cols="12" sm="6" md="4">
                  <v-select
                      :items="enrolOptions"
                      v-model="search.enrol_option_id"
                      v-on:keyup.enter="searchEnrolOptionScore"
                      @input="search.enrol_option_id = $event !== null ? $event : ''"
                      label="Enrol Option"
                      item-text="name"
                      item-value="id"
                      clearable
                      outlined
                      dense
                  ></v-select>
                </v-col>-->

                <v-col cols="12" sm="6" md="4">
                  <v-btn
                      @click.prevent="searchEnrolOptionScore"
                      class="mt-1 btn btn-block btn-primary"
                      style="color: #fff;"
                      :loading="loading"
                  >
                    <v-icon small  elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </v-row>
            </div>

            <div class="table-responsive">
              <v-skeleton-loader
                  v-if="loading"
                  type="table-thead"
              >
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="loading"
                  type="table-row-divider@25"
              >
              </v-skeleton-loader>
              <table class="table" v-if="!loading">
                <thead>
                <tr class="px-3">
                  <th class="px-3" :class="sortedClass('score_id')" @click="sortBy('score_id')">Score</th>
                  <th class="px-3">Enrol option</th>
                  <th class="pr-3 text-center">Options</th>
                </tr>
                </thead>
                <tbody>
                <template>
                  <tr v-for="(enrolOptionScore, index) in sortedItems" :key="index" >
                    <td class="px-3">
                      <a @click="editEnrolOptionScore(enrolOptionScore)" class="text-primary font-weight-bolder text-hover-primary mb-1">
                        {{ enrolOptionScore.score  ? enrolOptionScore.score : '--' }}
                      </a>
                    </td>
                    <td class="text-left">
                      {{ enrolOptionScore.enrol_option ? enrolOptionScore.enrol_option : '--' }}
                    </td>
                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item" v-if="enrolOptionScore.edit">
                              <a @click="editEnrolOptionScore(enrolOptionScore)" class="navi-link">
                                <span class="navi-icon">
                                    <i class="fas fa-edit"></i>
                                </span>
                                <span class="navi-text">Edit</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item">
                              <a class="navi-link" @click.prevent="deleteBlog(enrolOptionScore.id)">
                                <span class="navi-icon">
                                    <i class="fas fa-trash"></i>
                                </span>
                                <span class="navi-text">Delete</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                  <tr v-if="sortedItems.length == 0" >
                    <td colspan="3" class="text-center"><strong>No data found</strong></td>
                  </tr>
                </template>
                </tbody>
              </table>
              <b-pagination
                  v-if="sortedItems.length > 0"
                  class="pull-right mt-7"
                  @input="getAllEnrolOptionScores"
                  :disabled="loading"
                  v-model="page"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number
                  last-number
              ></b-pagination>
            </div>
          </div>

          <create-or-update ref="create-or-update" @refresh="getAllEnrolOptionScores"></create-or-update>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>


import EnrolOptionScoreDescriptionService from "@/services/enrol-option-score-descritpion/EnrolOptionScoreDescriptionService";
import EnrolOptionService from "@/services/enrol-option/EnrolOptionService";
import ScoreService from "@/services/score/score/ScoreService";
import CreateOrUpdate from "./CreateAndUpdate";

const enrolOptionScore = new EnrolOptionScoreDescriptionService();
const enrolOption = new EnrolOptionService();
const score = new ScoreService();

export default {
  components: {CreateOrUpdate},
  data() {
    return{
      menu: false,
      loading: false,
      enrolOptionScores:[],
      scores:[],
      enrolOption:{},
      enrolOptionId: null,
      currentUser: {},
      total: null,
      perPage: null,
      page: null,
      sort: {
        key: '',
        isAsc: false
      },
      search:{
        score_id: '',
        enrol_option_id: '',
      }
    }
  },
  mounted() {
    this.enrolOptionId = this.$route.params.enrolOptionId;
    if(this.enrolOptionId != undefined && this.enrolOptionId != null) {
      this.search.enrol_option_id = this.enrolOptionId;
    }
    this.getAllEnrolOptionScores();
    this.getAllScores();
    this.getEnrolOption();
    this.getCurrentUser();
  },
  computed: {
    sortedItems() {
      const list = this.enrolOptionScores.slice();
      if (!!this.sort.key) {
        list.sort((a, b) => {
          a = a[this.sort.key]
          b = b[this.sort.key]
          return (a === b ? 0 : a > b ? 1 : -1) * (this.sort.isAsc ? 1 : -1)
        });
      }
      return list;
    },
  },
  methods: {
    sortedClass(key) {
      return this.sort.key === key ? `sorted ${this.sort.isAsc ? 'asc' : 'desc'}` : '';
    },
    sortBy(key) {
      this.sort.isAsc = this.sort.key === key ? !this.sort.isAsc : false;
      this.sort.key = key;
    },
    createEnrolOptionScore(){
      this.$refs['create-or-update'].createEnrolOptionScore();
    },
    editEnrolOptionScore(enrolOptionScore){
      this.$refs['create-or-update'].editEnrolOptionScore(enrolOptionScore);
    },
    getCurrentUser() {
      this.currentUser = this.$store.getters.currentUser;
    },
    getAllEnrolOptionScores(){
      this.loading = true;
      enrolOptionScore
          .paginate(this.search,this.page)
          .then(response => {
            this.enrolOptionScores = response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
            this.$snotify.error("Oops something went wrong");
          });
    },
    getAllScores(){
      score
          .all()
          .then(response => {
            this.scores = response.data.scores;
          })
          .catch((err) => {
            this.$snotify.error("Oops something went wrong");
          });
    },
    getEnrolOption(){
      enrolOption
          .show(this.enrolOptionId)
          .then(response => {
            this.enrolOption = response.data.enrolOption;
          })
          .catch((err) => {
            this.$snotify.error("Oops something went wrong");
          });
    },
    searchEnrolOptionScore(){
      this.getAllEnrolOptionScores();
    },
    deleteBlog(id) {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            enrolOptionScore
                .delete(id)
                .then((response) => {
                  this.getAllEnrolOptionScores()
                  this.$snotify.success("Enrol button information deleted ");
                })
                .catch((err) => {
                  this.$snotify.error("Oops something went wrong");
                });
          }
        },
      });
    },
  }
}
</script>
<style lang="sass">
table
  th.sorted
    &.asc::after
      display: inline-block
      content: '▼'
      color: red

    &.desc::after
      display: inline-block
      content: '▲'
      color: red

</style>