<template>
  <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      scrollable
  >
    <v-card>
      <v-toolbar dark>
        <v-card-title>
          <span>{{ title }} Score on not offering list</span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <i class="fa fa-close"></i>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <v-container>
          <v-row>

            <v-col cols="12" sm="12" md="12" v-if="currentUser.access_type == 'federal' && !edit">
              <v-select
                  label="SCORE"
                  v-model="enrolOptionScore.score_id"
                  :items="scores"
                  item-text="name"
                  item-value="id"
                  outlined
                  dense
              >
              </v-select>
            </v-col>

            <v-col cols="12" sm="12" md="12" v-if="edit">
              <v-text-field
                  label="SCORE"
                  v-model="enrolOptionScore.score"
                  disabled
                  outlined
                  dense
              >
              </v-text-field>
            </v-col>

<!--            <v-col cols="12" sm="12" md="12">-->
<!--              <v-select-->
<!--                  label="Enrol Option"-->
<!--                  v-model="enrolOptionScore.enrol_option_id"-->
<!--                  :items="enrolOptions"-->
<!--                  item-text="name"-->
<!--                  item-value="id"-->
<!--                  outlined-->
<!--                  dense-->
<!--              >-->
<!--                <template v-slot:label>-->
<!--                  Enrol Option <span class="text-danger">*</span>-->
<!--                </template>-->
<!--              </v-select>-->
<!--            </v-col>-->

            <v-col cols="12" md="12">
              Description <span class="text-danger">*</span>
              <ckeditor
                  :config="editorConfig"
                  v-model="enrolOptionScore.description" >
              </ckeditor>
              <span class="text-danger" v-if="$v.enrolOptionScore.description.$error">This information is required</span>
              <span class="text-danger" v-if="errors.description" >* {{ errors.description[0] }}</span>
            </v-col>

          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            :loading="loading"
            color="btn btn-primary"
            dark
            medium
            @click="createOrUpdate()"
        >
          Save
        </v-btn>
        <v-btn
            color="btn btn-standard"
            dark
            medium
            @click="dialog = false"
        >
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ScoreService from "@/services/score/score/ScoreService";
import EnrolOptionScoreDescriptionService from "@/services/enrol-option-score-descritpion/EnrolOptionScoreDescriptionService";
import EnrolOptionService from "@/services/enrol-option/EnrolOptionService";
import {required } from "vuelidate/lib/validators";

const enrolOptionScore = new EnrolOptionScoreDescriptionService();
const enrolOption = new EnrolOptionService();
const score = new ScoreService();

export default {
  name: "CreateAndUpdate",
  validations: {
    enrolOptionScore: {
      enrol_option_id: { required },
      description: { required }
    }
  },
  data() {
    return {
      title: '',
      dialog: false,
      loading: false,
      edit: false,
      errors: [],
      enrolOptions: [],
      enrolOption: {},
      scores: [],
      currentUser: {},
      enrolOptionId: null,
      enrolOptionScore: {
        score_id:'',
        enrol_option_id:'',
        description:'',
      },
      editorConfig: {
        toolbar: [ [ 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList' ] ],
        versionCheck: false
      },
    }
  },
  methods: {
    closeDialog() {
      this.dialog=false;
      this.resetForm();
    },
    createEnrolOptionScore() {
      this.resetForm();
      this.title = 'Add';
      this.dialog = true;
      this.edit = false;
    },
    editEnrolOptionScore(enrolOptionScore) {
      this.resetForm();
      this.title = 'Update';
      this.dialog = true;
      this.edit = true;
      this.enrolOptionScore = enrolOptionScore;
    },
    getCurrentUser() {
      this.currentUser = this.$store.getters.currentUser;
    },
    getAllEnrolOption() {
      enrolOption
          .all()
          .then(response => {
            this.enrolOptions = response.data.enrolOptions;
          })
          .catch((err) => {
            this.$snotify.error("Oops something went wrong");
          });
    },
    getAllScore() {
      enrolOptionScore
          .filterSores()
          .then(response => {
            this.scores = response.data.scores;
          })
          .catch((err) => {
            this.$snotify.error("Oops something went wrong");
          });
    },
    getEnrolOptionDetail() {
      enrolOption.show(this.enrolOptionId).then(response => {
        this.enrolOption = response.data.enrolOption;
        if(this.enrolOption && this.enrolOption.score_ids.length > 0) {
          this.enrolOption.score_ids.forEach(scoreId => {
            let index = this.scores.indexOf(this.scores.find(score => score.id == scoreId));
            this.scores.splice(index, 1)
          });
        }
      });
    },
    createOrUpdate() {
      if(this.enrolOptionId != undefined && this.enrolOptionId != null) {
        this.enrolOptionScore.enrol_option_id = this.enrolOptionId;
      }
      this.$v.$touch();
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      } else {
        if (!this.edit) this._create();
        else this.update();
      }
    },
    _create: function() {
      this.loading = true;
      enrolOptionScore
          .create(this.enrolOptionScore)
          .then(response => {
            this.$snotify.success("Enrol button information added");
            this.closeDialog();
            this.$emit('refresh');
            this.getAllScore();
            this.getEnrolOptionDetail();
            this.loading = false;
          })
          .catch((err) => {
            this.errors = err.errors;
            this.$snotify.error("Oops something went wrong");
          });
    },
    update() {
      this.loading = true;
      enrolOptionScore
          .update(this.enrolOptionScore.id, this.enrolOptionScore)
          .then(response => {
            this.$snotify.success("Enrol button information updated");
            this.closeDialog();
            this.$emit('refresh');
            this.loading = false;
          })
          .catch((err) => {
            this.errors = err.errors;
            this.$snotify.error("Oops something went wrong");
          });
    },
    resetForm() {
      this.errors = [];
      this.$v.$reset();
      this.enrolOptionScore = {
        score_id:'',
        enrol_option_id:'',
        description:'',
      }
    },
  },
  mounted() {
    this.enrolOptionId = this.$route.params.enrolOptionId;
    this.getAllScore();
    this.getAllEnrolOption();
    this.getEnrolOptionDetail();
    this.getCurrentUser();
  }
}
</script>

<style scoped>

</style>
